// Components
import FromContact from '../components/FromContact'
// BG
import bgcontact from '../files/bg-contact.jpeg'
// Icons
import { FaGoogle, FaPhoneAlt, FaMap } from 'react-icons/fa'
// Logo
import logodm from '../files/dmlogo.png'

const Contact = () => (
  <div
    className='w-full bg-cover bg-center grid justify-items-center'
    style={{ backgroundImage: `url(${bgcontact})` }}
  >
    <div className='grid sm:grid-cols-2 grid-cols-1 gap-4 mx-auto container'>
      <div className='grid justify-items-left mt-5 ml-5 text-white'>
        <a href='mailto:ventas@dmgroup.mx' className='m-5 flex items-center font-medium'>
          <FaGoogle className='text-5xl mx-10 text-white-600' /> ventas@dmgroup.mx
        </a>
        <a href='tel:4422782572' className='m-5 flex items-center font-medium'>
          <FaPhoneAlt className='text-5xl mx-10 text-white-600' /> Oficina: 442 278 25 72
        </a>
        <a href='tel:4461473932' className='m-5 flex items-center font-medium'>
          <FaPhoneAlt className='text-5xl mx-10 text-white-600' /> Celular: 446 147 3932
        </a>
        <div className='m-5 flex items-center font-medium'>
          <FaMap className='text-5xl mx-10 text-white-600' /> Dirección: Lago Cuitzeo 305 , local 4 , planta baja , Cumbres del Lago , Juriquilla Qro , 76230
        </div>
      </div>
      <div className='py-10 my-10 sm:md:text-left text-center grid justify-items-center rounded sm:md:bg-transparent backdrop-blur-xl'>
        <FromContact />
      </div>
      <div className='sm:col-span-2 col-span-1 grid justify-items-center'>
        <img src={logodm} alt='logodm' className='w-1/3 p-10 m-10' />
      </div>
    </div>
  </div>
)

export default Contact
