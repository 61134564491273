import { FaRegCircle } from 'react-icons/fa'
import bg from '../files/smart-home.jpeg'

const ViewLi = ({ text }) => (
  <p className='text-justify sm:md:text-lg text-md flex items-center text-gray-600 mr-5 pb-2'><FaRegCircle className='text-red-900 mx-2' />  {text}</p>
)

const Domotica = () => (
  <div className='container mx-auto p-10 m-10'>
    <div className='grid sm:md:grid-cols-2 grid-cols-1'>
      <img className='rounded-sm w-full h-full p-10' src={bg} alt='s' />
      <div className='w-full text-justify'>
        <p className='sm:md:text-2xl text-xl font-bold text-red-500 mb-2'>Domótica</p>
        <p className='sm:md:text-lg text-md font-medium text-gray-600 mb-4'>La automatización residencial y comercial que ofrece ahorro, confort y seguridad es un sistema que integra otros, para poderlos controlar desde un solo dispositivo móvil, por ejemplo, iluminación, temperatura, cámaras de seguridad, alarma, audio, pantallas, persianas, entre otros.</p>
        <p className='sm:md:text-2xl text-xl font-bold text-red-500 mb-2'>Beneficios</p>
        <ViewLi text='La domótica o automatización de casas inteligentes, permite crear escenas de confort en cada espacio, desde el dispositivo móvil de tu preferencia, por ejemplo, escenas de Cine, Fiesta, Cena, Lectura.' />
        <ViewLi text='La automatización en Smart Hotels, propicia el ahorro de energía, al poder controlar todos los sistemas desde un solo punto, como iluminación y temperatura principalmente, por lo que se pueden obtener reportes en tiempo real del consumo.' />
        <ViewLi text='La aplicación principal de la automatización en Smart Offices, es el ahorro del tiempo, por ejemplo para acondicionar una sala de juntas, desde la preparación del proyector, de la iluminación correcta de acuerdo a la junta, el contenido que se va a proyectar, etc., todo esto lo podemos lograr con un solo botón, sin mencionar la excelente imagen de la empresa.' />
      </div>
    </div>
  </div>
)

export default Domotica
