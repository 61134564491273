import Footer from './assets/components/Footer.js'
import Contact from './assets/components/Contact.js'
import Header from './assets/components/Header.js'
// Icons
import { FaFacebook } from 'react-icons/fa'
import { BsLinkedin } from 'react-icons/bs'
import AddLog from './assets/components/AddLog.js'
import ImgSection from './assets/components/ImgSection.js'
import FunctionsAndServices from './assets/components/FunctionsAndServices.jsx'
import ControlsAndRegister from './assets/components/ControlsAndRegister.jsx'
import Cctv from './assets/components/CCTV.jsx'
import Domotica from './assets/components/Domotica.jsx'

function App () {
  return (
    <div className='flex flex-col bg-slate-100 w-full h-screen text-center'>
      <Header
        className='mb-10'
        title='ponga su seguridad en nuestras manos'
        text='su tranquilidad es lo mas importante para nosotros'
      />
      <div className='sm:md:m-40 m-0 bg-stone-400'> </div>
      <AddLog />
      <ImgSection />
      <FunctionsAndServices />
      <ControlsAndRegister />
      <Cctv />
      <Domotica />
      <div className='w-full bg-gray-900/80'>
        <div className='grid justify-items-center p-2'>
          <div className='flex items-center'>
            <a href='https://www.facebook.com/dmgroup.mx'><FaFacebook className='hover:text-7xl text-6xl m-5 hover:animate-bounce hover:text-gray-900/80 hover:bg-white text-white bg-gray-900/80 focus:outline-none focus:ring-6 focus:ring-gray-300 rounded-full dark:focus:ring-gray-900/80' /></a>
            <a href='https://linkedin.com/company/dm1group'><BsLinkedin className='hover:text-7xl text-6xl m-5 hover:animate-bounce hover:text-gray-900/80 hover:bg-white text-white bg-gray-900/80 focus:outline-none focus:ring-6 focus:ring-gray-300 rounded-full dark:focus:ring-gray-900/80' /></a>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </div>
  )
}

export default App
