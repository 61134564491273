import '../../App.css'
import bg from '../files/bg-initial-contel.jpeg'
import logocontel from '../files/logo.svg'
// Logotipos single
import iconcd from '../files/icon-cd.png'
import iconre from '../files/icon-re.png'
import iconsi from '../files/icon-si.png'
import iconsl from '../files/icon-sl.png'

const Header = ({ title, text }) => {
  const ElementIcon = ({ icon, title, text }) => (
    <div className='bg-white grid justify-items-center sm:md:p-5 p-10'>
      <img src={icon} alt='logo' className='h-20 w-20' />
      <p className='text-2xl py-4 font-light text-gray-900'>{title}</p>
      <p className='text-sm sm:md:-mx-2 font-light text-gray-700'>{text}</p>
    </div>
  )
  return (
    <header>
      <div
        className='w-full bg-fixed bg-auto bg-center grid justify-items-center h-screen'
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className='flex items-center justify-center h-full w-full'>
          <div className='grid justify-items-center sm:md:m-5 sm:md:p-5 container'>
            <img src={logocontel} alt='logo' className='sm:md:w-2/5 1/5 sm:md:bg-transparent bg-dark-300/70 p-2' />
            {title.length > 0 && (<p className='text-white sm:md:text-3xl text-xl font-bold uppercase sm:md:mx-10 sm:md:px-10 mx-2 px-2 py-2 mt-5 bg-gradient-to-r from-red-900 via-red-600 to-red-800'>{title}</p>)}
            {text.length > 0 && (<p className='text-white sm:md:text-2xl text-lg pt-5 pb-5 font-medium sm:md:mx-10 sm:md:px-10 mx-2 px-2'>{text}</p>)}
          </div>
        </div>
      </div>
      <div className='grid sm:md:grid-cols-5 grid-cols-1'>
        <div> </div>
        <div className='col-span-3 grid sm:md:grid-cols-4 grid-cols-1 sm:md:lg:-m-35 -mt-40 bg-white justify-items-center shadow'>
          <ElementIcon
            icon={iconcd}
            title='Seguridad Intramuros'
            text='Nuestros elementos de seguridad física vigilan y salvaguardan los bienes muebles e inmuebles, así como al personal que en ellos se encuentran.'
          />
          <ElementIcon
            icon={iconre}
            title='Recepción Ejecutiva'
            text='Buscamos satisfacer las necesidades de comunicación ejecutando y controlando la recepción, atendiendo al público en sus requerimientos de información.'
          />
          <ElementIcon
            icon={iconsi}
            title='CCTV y Domótica'
            text='Ofrecemos todas las soluciones en video vigilancia tanto de forma local como en remoto, contamos con la tecnología más innovadora del sector.'
          />
          <ElementIcon
            icon={iconsl}
            title='Servicio de Limpieza'
            text='Ofrecemos una serie de servicios de limpieza, en oficinas corporativas, centros comerciales, sector educativo, industria alimenticia, entre Muchos otros.'
          />
        </div>
        <div> </div>
      </div>
    </header>
  )
}

export default Header
