import { useState } from 'react'
// Areas
import monitoreo from '../files/monitoreo.jpeg'
import ac from '../files/area-comercial.jpeg'
import ae from '../files/area-empresarial.jpeg'
import aes from '../files/area-escolar.jpeg'
import ar from '../files/area-residencial.jpeg'

const ImgSection = () => {
  const [area, setArea] = useState('area-comercial')
  const AreaSelected = ({ name, text, image }) => {
    return (
      <div className='m-2 p-2 grid sm:md:lg:grid-cols-2 grid-cols-1'>
        <div className='grid sm:md:lg:justify-items-center justify-items-start'>
          <p className='sm:md:text-3xl text-xl font-light '>{name}</p>
          <div className='text-justify'>
            {
              text.map((item, index) => {
                return (
                  <li className='sm:md:text-lg text-xs py-1 font-light list-none hover:list-disc' key={index}>
                    {item}
                  </li>
                )
              })
            }
          </div>
        </div>
        <img src={image} alt='name' className='sm:md:w-full sm:md:h-full w-4/5 h-4/5 m-10' />
      </div>
    )
  }
  const ImgButton = ({ icon, option }) => (
    <button className='hover:animate-pulse focus:blur-sm'>
      <img src={icon} alt={option} className='h-20 w-20 focus:blur-sm' onClick={() => setArea(option)} />
    </button>
  )
  return (
    <div className='w-full bg-gray-100/80'>
      <div className='mx-auto container bg-slate-100 py-20'>
        <div className='grid sm:md:grid-cols-3 grid-cols-1'>
          <div className='grid sm:md:grid-cols-1 grid-cols-5 gap-4 justify-items-center'>
            <ImgButton option='area-comercial' icon={ac} />
            <ImgButton option='area-empresarial' icon={ae} />
            <ImgButton option='area-escolar' icon={aes} />
            <ImgButton option='area-residencial' icon={ar} />
            <ImgButton option='monitoreo' icon={monitoreo} />
          </div>
          <div className='text-justify col-span-2 sm:md:px-0 px-10'>
            {(() => {
              switch (area) {
                case 'area-comercial':
                  return (
                    <AreaSelected
                      name='Área Comercial'
                      image={ac}
                      text={
                        [
                          'Prevención y detección de farderos',
                          'Mantenimiento de orden y paz pública',
                          'Manejo de alarmas',
                          'Vigilancia en estacionamientos',
                          'Prevención del robo hormiga'
                        ]
                      }
                    />
                  )
                case 'area-empresarial':
                  return (
                    <AreaSelected
                      name='Área Empresarial / Industrial'
                      image={ae}
                      text={
                        [
                          'Manejo de asistencia de personal',
                          'Operación de conmutadores',
                          'Control de andenes',
                          'Control de gafetes',
                          'Control de acceso a visitantes',
                          'Manejo de CCTV',
                          'Combate de incendios en primera instancia'
                        ]
                      }
                    />
                  )
                case 'area-escolar':
                  return (
                    <AreaSelected
                      name='Área Escolar'
                      image={aes}
                      text={
                        [
                          'Control de salida y entrega de alumnos a padres de familia o personas autorizadas',
                          'Control de tráfico vehicular',
                          'Control de acceso a visitantes',
                          'Resguardo perimetral'
                        ]
                      }
                    />
                  )
                case 'area-residencial':
                  return (
                    <AreaSelected
                      name='Área Residencial'
                      image={ar}
                      text={
                        [
                          'Guardias en caseta o lobby',
                          'Control de acceso a visitantes',
                          'Resguardo perimetral',
                          'Encendido y apagado de luces',
                          'Vigilancia de estacionamientos'
                        ]
                      }
                    />
                  )
                case 'monitoreo':
                  return (
                    <AreaSelected
                      name='Monitoreo'
                      image={monitoreo}
                      text={
                        [
                          'Sistemas de detección de incendios',
                          'Circuito cerrado de televisión',
                          'Sistemas de alarma antirrobo',
                          'Monitoreo de alarmas',
                          'Monitoreo remoto de video',
                          'Controles de acceso',
                          'Sistemas de señalización de evacuación',
                          'Seguimiento satelital de móviles'
                        ]
                      }
                    />
                  )
                default:
                  console.log('Selecciona algo boludo')
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImgSection
