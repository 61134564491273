import { FaRegCircle } from 'react-icons/fa'
import bg from '../files/bg-controls-and-register.jpeg'

const FunctionsAndServices = () => {
  const ViewLi = ({ text }) => (
    <p className='text-justify sm:md:text-xl text-md flex items-center text-white mr-5'><FaRegCircle className='text-red-400 mx-2 p-1' />  {text}</p>
  )

  return (
    <div>
      <div
        className='w-full bg-auto grid justify-items-end py-10'
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className='grid sm:md:grid-cols-2 grid-cols-1'>
          <div> </div>
          <div className='sm:md:col-span-1 col-span-2'>
            <h1 className='mt-10 pt-10 mr-10 text-3xl text-white uppercase'>CONTROLES Y</h1>
            <h1 className='mb-10 pb-10 mr-10 text-3xl text-white uppercase'>REGISTRO</h1>
            <ViewLi text='Parte diario de novedades.' />
            <ViewLi text='Marbetes de identificación vehicular.' />
            <ViewLi text='Control de acceso vehicular y de personal.' />
            <ViewLi text='Registro de residentes, visitantes y servicios.' />
            <ViewLi text='Recepción y entrega de correspondencia y mensajería.' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FunctionsAndServices
