import { FaRegCircle } from 'react-icons/fa'
import bg from '../files/seg-cctv.jpeg'

import cam1 from '../files/cam-1.jpeg'

const ViewLi = ({ text }) => (
  <p className='text-justify sm:md:text-lg text-md flex items-center text-gray-600 mr-5'><FaRegCircle className='text-red-400 mx-2 p-1' />  {text}</p>
)

const Cctv = () => (
  <div className='container mx-auto p-10 m-10'>
    <div className='grid sm:md:grid-cols-2 grid-cols-1'>
      <div className='w-full text-justify'>
        <p className='sm:md:text-2xl text-xl font-bold text-red-500 mb-2'>CCTV</p>
        <p className='sm:md:text-lg text-md font-medium text-gray-600 mb-4'>Somos una empresa preocupada por la seguridad, por lo cual proveemos equipos garantizados y de última generación, para salvaguardar el patrimonio e integridad de nuestros clientes.</p>
        <ViewLi text='Buscamos mejorar cualquier propuesta.' />
        <ViewLi text='Ofrecemos pólizas de mantenimiento a precio preferencial.' />
        <ViewLi text='Todos nuestros productos cuentan con garantía.' />
        <ViewLi text='Nuestros Instaladores están certificados y con las capacidades necesarias para cualquier tipo de instalación en cualquier material.' />
        <ViewLi text='Contamos con precios competitivos' />
        <ViewLi text='Somos importadores directos de las mejores marcas' />
      </div>
      <img className='rounded-sm w-full h-full p-10' src={bg} alt='s' />
      <div className='sm:md:col-span-2 col-span-1'>
        <img src={cam1} alt='' />
      </div>
    </div>
  </div>
)

export default Cctv
