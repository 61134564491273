import logocontel from '../files/logo.svg'
import bg from '../files/bg-nos.jpeg'

const AddLog = () => {
  return (
    <div
      className='w-full bg-auto bg-center grid justify-items-center h-screen py-10'
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className='flex items-center justify-center h-full w-full'>
        <div className='grid justify-items-center sm:md:m-5 sm:md:p-5 container'>
          <img src={logocontel} alt='logo' className='sm:md:w-2/5 1/5 sm:md:bg-transparent bg-dark-300/70 p-2' />
          <p className='text-white sm:md:text-md text-lg pt-5 pb-5 font-light sm:md:mx-40 sm:md:px-20 mx-10 px-0'>
            Somos una empresa totalmente comprometida con la seguridad de nuestros cliente, personal altamente capacitado e investigado, para cualquier tipo de situaciones
            <br /> <br />
            Ofrecemos el servicio de seguridad profesional intramuros, recepción ejecutiva y CCTV para su condominio, empresa, corporativo, escuelas, comercio e industrias, por personal altamente calificado y adiestrado.
            <br /> <br />
            <span className='font-bold text-white'>Llevamos la seguridad al siguiente nivel.</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default AddLog
