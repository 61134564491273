import { FaRegCircle } from 'react-icons/fa'
import bg from '../files/bg-funcions-and-services.jpeg'

const FunctionsAndServices = () => {
  const elementsLi1 = [
    'Elementos de seguridad privada de 12 y 24 hrs. de servicio.',
    'Supervisión las 24 hrs. y 365 dias en unidades tipo patrulla.',
    'Apoyo canino con adistramiento en guardia y protección.',
    'Radio-comunicación enlazada a la central de monitoreo.'
  ]
  const elementsLi2 = [
    'Equipo de circuito cerrado de tv y control de alarmas.',
    'Motocicletas para realizar rondines internos.',
    'Prevención y combate contra incendios.',
    'Designación de rutas de evacuación.'
  ]

  const ViewLi = ({ text }) => (
    <p className='text-justify sm:md:text-xl text-md flex items-center'><FaRegCircle className='text-red-400 mx-2 p-1' />  {text}</p>
  )

  return (
    <div>
      <div
        className='w-full bg-cover bg-center grid justify-items-end py-10'
        style={{ backgroundImage: `url(${bg})` }}
      >
        <h1 className='mt-10 pt-10 mr-10 text-5xl text-white uppercase'>Funciones y</h1>
        <h1 className='mb-10 pb-10 mr-10 text-5xl text-white uppercase'>Servicios</h1>
      </div>
      <div className='container mx-auto'>
        <div className='grid sm:md:grid-cols-2 grid-cols-1 gap-8'>
          <div className='mx-10 sm:md:p-10 py-2 sm:md:mt-0 mt-4'>
            <ul className='list-disc list-inside'>
              {Object.entries(elementsLi1).map((text, index) => (
                <ViewLi text={text} key={index++} />
              ))}
            </ul>
          </div>
          <div className='mx-10 sm:md:p-10 p-2 sm:md:mb-0 mb-4'>
            <ul className='list-disc list-inside'>
              {Object.entries(elementsLi2).map((text, index) => (
                <ViewLi text={text} key={index++} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>

  )
}

export default FunctionsAndServices
